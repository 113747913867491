@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "AzoSans-Light";
  src: url("./fonts/AzoSans-Light.ttf");
}

@font-face {
  font-family: "AzoSans-Thin";
  src: url("./fonts/AzoSans-Thin.ttf");
}

@font-face {
  font-family: "AzoSans-Bold";
  src: url("./fonts/AzoSans-Bold.ttf");
}

@font-face {
  font-family: "AzoSans-Black";
  src: url("./fonts/AzoSans-Black.ttf");
}

@font-face {
  font-family: "AzoSans-Medium";
  src: url("./fonts/AzoSans-Medium.ttf");
}

@font-face {
  font-family: "AzoSans-Regular";
  src: url("./fonts/AzoSans-Regular.ttf");
}